import { useCallback, useContext } from 'react';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';

import { useLoomEntryPointVariantState } from './useLoomEntryPointVariantState';

type triggerCrossFlowSuccessCheckType = {
	onCrossflowFailure?: () => void;
};

export const useTriggerCrossFlowSuccessCheck = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const [, { crossFlowSuccessCheck }] = useLoomEntryPointVariantState();
	const { cloudId } = useSessionData();

	const triggerCrossFlowSuccessCheck = useCallback(
		({ onCrossflowFailure }: triggerCrossFlowSuccessCheckType) => {
			// cross-flow would only be able to update the variant if the user is an admin
			// end-users get taken through request-trial flow, which does not provision Loom
			if (isSiteAdmin) {
				crossFlowSuccessCheck({ cloudId, onCrossflowFailure });
			}
		},
		[cloudId, isSiteAdmin, crossFlowSuccessCheck],
	);

	return { triggerCrossFlowSuccessCheck };
};
