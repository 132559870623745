import { useEffect } from 'react';
import { createStore, createHook, type Action } from 'react-sweet-state';

import type {
	OpenLoomRecorderParams,
	UseLoomRecorderEntryPointProps,
} from './useLoomRecorderEntryPoint';
import { useLoomRecorderEntryPoint } from './useLoomRecorderEntryPoint';
import type { EntryPointId } from './LoomCrossSellPopupCard/BaseLoomPopupCard';

export const LoomRecorderTriggerEvent = {
	CROSS_FLOW_SUCCESS: 'cross-flow-success',
} as const;

export type LoomRecorderTriggerEventType =
	(typeof LoomRecorderTriggerEvent)[keyof typeof LoomRecorderTriggerEvent];

export type LoomRecorderTrigger = {
	event: LoomRecorderTriggerEventType;
	entryPointId: EntryPointId;
};

interface State {
	triggers: LoomRecorderTrigger[];
}

const initialState: State = {
	triggers: [],
};

/**
 * compare if two triggers are the same
 */
const isSameTrigger = (a: LoomRecorderTrigger, b: LoomRecorderTrigger) =>
	a.event === b.event && a.entryPointId === b.entryPointId;

/**
 * check if a trigger is in the trigger list
 */
const hasTrigger = (triggers: LoomRecorderTrigger[], trigger: LoomRecorderTrigger) => {
	for (const t of triggers) {
		if (isSameTrigger(t, trigger)) {
			return true;
		}
	}
	return false;
};

const actions = {
	triggerLoomRecorder:
		(trigger: LoomRecorderTrigger): Action<State> =>
		({ getState, setState }) => {
			const { triggers } = getState();
			if (!hasTrigger(triggers, trigger)) {
				setState({ triggers: [...triggers, trigger] });
			}
		},
	markTriggered:
		(trigger: LoomRecorderTrigger): Action<State> =>
		({ getState, setState }) => {
			const { triggers } = getState();
			if (hasTrigger(triggers, trigger)) {
				setState({
					triggers: triggers.filter((t) => !isSameTrigger(t, trigger)),
				});
			}
		},
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'confluence-loom-utils.loom-recorder-trigger',
	initialState,
	actions,
});

const useLoomRecorderTriggerStore = createHook(store);

/**
 * Use this hook at the component you want to programmatically open the SDK from. The trigger can be invoked even after
 * the component rendering the hook has been unmounted (eg. in a callback).
 *
 * @returns a function to open the SDK at the listener point, with a given event name
 */
export const useLoomRecorderTrigger = () => {
	const [, { triggerLoomRecorder, markTriggered }] = useLoomRecorderTriggerStore();
	return { triggerLoomRecorder, markTriggered };
};

/**
 * Use this listener at the component you want to trigger the SDK at (ie. where you want to define onInsert, etc)
 *
 * @param trigger the trigger to listen for to trigger the record SDK
 * @param openLoomRecorderParams the openLoomRecorder params to pass through
 * @param entrypointProps the useJiraLoomRecorderEntrypoint props to pass through
 */
export const useLoomRecorderTriggerListener = (
	trigger: LoomRecorderTrigger,
	openLoomRecorderParams: OpenLoomRecorderParams | undefined,
	entrypointProps: UseLoomRecorderEntryPointProps,
) => {
	const [{ triggers }, { markTriggered }] = useLoomRecorderTriggerStore();

	const { openLoomRecorder, isLoomRecorderInitialized } =
		useLoomRecorderEntryPoint(entrypointProps);

	useEffect(() => {
		if (isLoomRecorderInitialized && openLoomRecorder !== null && hasTrigger(triggers, trigger)) {
			void openLoomRecorder(openLoomRecorderParams);
			void markTriggered(trigger);
		}
	}, [
		trigger,
		markTriggered,
		openLoomRecorder,
		isLoomRecorderInitialized,
		triggers,
		openLoomRecorderParams,
	]);
};
