import { useCallback, useContext } from 'react';
import { useCrossFlow, Journeys, Targets } from '@atlassiansox/cross-flow-support';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { SPAViewContext } from '@confluence/spa-view-context';
import { isConfluenceRedirectToLoomSDKExperimentEnabled } from '@confluence/growth-experiment-helpers';

import { type EntryPointId } from './LoomCrossSellPopupCard/BaseLoomPopupCard';
import { useLoomRecorderLoadingBlanket } from './useLoomRecorderLoadingBlanket';
import { useTriggerCrossFlowSuccessCheck } from './useTriggerCrossFlowSuccessCheck';
import type { LoomRecorderTrigger } from './useLoomRecorderTrigger';
import { useLoomRecorderTrigger, LoomRecorderTriggerEvent } from './useLoomRecorderTrigger';
type CrossFlowRequestAccessOptions = {
	sourceComponent: EntryPointId;
};

export const useLoomCrossFlowRequestAccessCallback = ({
	sourceComponent,
}: CrossFlowRequestAccessOptions) => {
	const crossFlow = useCrossFlow();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { isSiteAdmin } = useContext(SPAViewContext);
	const [, { showBlanket, hideBlanket }] = useLoomRecorderLoadingBlanket();
	const { triggerCrossFlowSuccessCheck } = useTriggerCrossFlowSuccessCheck();
	const { triggerLoomRecorder, markTriggered } = useLoomRecorderTrigger();
	const { isVariantCohort } = isConfluenceRedirectToLoomSDKExperimentEnabled(); // evaluate experiment here but does NOT fire exposure event

	return useCallback(async () => {
		if (!crossFlow.isEnabled) {
			experienceTracker.fail({
				name: LOOM_CROSS_SELL_EXPERIENCE,
				error: new Error('Loom crossflow is not enabled'),
				attributes: {
					sourceComponent,
					adType: 'crossFlow',
				},
			});
			return;
		}

		experienceTracker.succeed({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			attributes: {
				sourceComponent,
				adType: 'crossFlow',
			},
		});

		/**
		 * - This flag is used to determine whether to redirect to SDK after cross-flow (i.e. open loom recorder in Confluence right after cross-flow)
		 * - Existing behavior is to redirect to loom.com after cross-flow
		 */
		if (isSiteAdmin && isVariantCohort) {
			/**
			 * we show a blanket for site admins in order to figure out whether cross flow actually provisioned a Loom.
			 * this blanket is shown here, but is hidden in various other places:
			 * - here, if cross-flow does not return success (this occurs if the drawer is closed via back button before CFFE initialises)
			 * - in triggerCrossFlowSuccessCheck, if it polls license-information but it did not actually become a co-use scenario
			 * it's bad that the logic is split across multiple places, but at least it is confined within confluence/loom-utils.
			 */
			void showBlanket(); // we show the blanket at the same time as opening cross-flow, so it's more seamless when it closes
		}

		try {
			/**
			 * `success` in the response only indicates whether or not the user closed the drawer via
			 * the "back arrow" button. It does *not* indicate that the user succesfully completed cross-flow.
			 *
			 * Additionally, if the user *does* go through cross-flow, they'll be navigated to Loom.com and
			 * not return to this code path.
			 */
			const { success } = await crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				sourceComponent,
				sourceContext: 'confluence',
				targetProduct: Targets.LOOM,
			});

			if (isSiteAdmin && isVariantCohort) {
				// success is meaningless and always returned regardless of whether the user clicked "Back/Cancel" or "Get Started/Try now"
				if (success) {
					const trigger: LoomRecorderTrigger = {
						event: LoomRecorderTriggerEvent.CROSS_FLOW_SUCCESS,
						entryPointId: sourceComponent,
					};

					void triggerLoomRecorder(trigger); // this adds the trigger to the list to be listened

					const onCrossflowFailure = () => {
						// callback function if cross-flow fails to provision Loom
						void markTriggered(trigger); // mark the trigger as triggered if cross-flow loom fails for some reason(xflow service down, user clicked "Back/Cancel" in CFFE, etc)
						void hideBlanket();
					};
					triggerCrossFlowSuccessCheck({ onCrossflowFailure }); // this checks if Loom successfully provisioned and switches our touchpoint variation to co-use
				} else {
					// this only happens if the user clicks the "<-" button in the drawer before CFFE initialises
					void hideBlanket();
				}
			}
		} catch (error: any) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.CONTENT_TYPES,
			});
		}
	}, [
		crossFlow,
		isSiteAdmin,
		sourceComponent,
		experienceTracker,
		isVariantCohort,
		showBlanket,
		hideBlanket,
		triggerLoomRecorder,
		markTriggered,
		triggerCrossFlowSuccessCheck,
	]);
};
