import type { FC } from 'react';
import React, { useCallback } from 'react';
import { styled } from '@compiled/react';

import { IconButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/loading-button';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { fg } from '@confluence/feature-gating';
import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { useLoomEntryPointVariant, useLoomRecorderEntryPoint } from '@confluence/loom-utils';
import { PageHeaderLoomButtonSpotlight } from '@confluence/loom-onboarding';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { type useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { LoomVideoIcon } from '../VideoIcon';

import { usePageHeaderLoomInsertion } from './usePageHeaderLoomInsertion';

type PageHeaderLoomButtonComponentProps = {
	contentId: string;
	dataVC?: string;
	label: string;
	spaceKey: string;
	contentType: string;
	flags: FlagsStateContainer;
	ssrPlaceholderIdProp?: ReturnType<typeof useSSRPlaceholderReplaceIdProp>;
	isCircle?: boolean;
	testId?: string;
	source?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonSpacer = styled.div({
	minWidth: '30px',
});

const PageHeaderLoomButtonComponent: FC<PageHeaderLoomButtonComponentProps> = ({
	contentId,
	dataVC,
	label,
	spaceKey,
	contentType,
	flags,
	ssrPlaceholderIdProp,
	isCircle = false,
	testId,
	source = 'pageHeader',
}) => {
	const { openLoomRecorderParams, postLoomRecordingCTA, resetInsertFunctionOnUnmount } =
		usePageHeaderLoomInsertion({ contentId, contentType, spaceKey });

	const { entryPointVariant } = useLoomEntryPointVariant();

	const { openLoomRecorder, isOpeningRecorder, isLoomRecorderInitialized, hasRecordingAccess } =
		useLoomRecorderEntryPoint({
			entryPointLocation: 'pageHeader',
			flags,
			resetInsertFunctionOnUnmount,
		});

	const shouldActivateLoomGAOnboarding =
		entryPointVariant === 'CO_USE' && hasRecordingAccess && isLoomRecorderInitialized;

	const [isLoomGAOnboardingActive, stopLoomGAOnboarding] = useSkippableCoordination(
		'cc-loom-onboarding-spotlight-editor',
		!(shouldActivateLoomGAOnboarding && fg('confluence_m25_onboarding_spotlights')),
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createLoomHeaderItem',
				source,
				attributes: {
					postLoomRecordingCTA,
				},
			},
		}).fire();

		void openLoomRecorder(openLoomRecorderParams);
	}, [
		createAnalyticsEvent,
		openLoomRecorder,
		openLoomRecorderParams,
		postLoomRecordingCTA,
		source,
	]);

	const handleSpotlightClose = useCallback(() => {
		if (isLoomGAOnboardingActive) {
			void stopLoomGAOnboarding();
		}
	}, [stopLoomGAOnboarding, isLoomGAOnboardingActive]);

	const LoomButtonWithTooltip = () => {
		return (
			<Tooltip content={label}>
				{(tooltipProps) => (
					<ButtonSpacer>
						{isCircle ? (
							<IconButton
								label={label}
								{...tooltipProps}
								isLoading={isOpeningRecorder}
								onClick={handleClick}
								appearance="subtle"
								shape="circle"
								icon={LoomVideoIcon}
								testId={testId}
							/>
						) : (
							<Button
								appearance="subtle"
								iconAfter={<LoomVideoIcon label="" color={token('color.text')} />}
								aria-label={label}
								{...tooltipProps}
								onClick={handleClick}
								isLoading={isOpeningRecorder}
								data-vc={dataVC}
								{...ssrPlaceholderIdProp}
								testId={testId}
							/>
						)}
					</ButtonSpacer>
				)}
			</Tooltip>
		);
	};

	if (
		isLoomGAOnboardingActive &&
		entryPointVariant === 'CO_USE' &&
		fg('confluence_m25_onboarding_spotlights')
	) {
		return (
			<PageHeaderLoomButtonSpotlight onTryClick={handleClick} onClose={handleSpotlightClose}>
				<LoomButtonWithTooltip />
			</PageHeaderLoomButtonSpotlight>
		);
	}

	return <LoomButtonWithTooltip />;
};

export const PageHeaderLoomButton = withFlags(PageHeaderLoomButtonComponent);
