import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { type LoomVideo } from '@loomhq/record-sdk';
import gql from 'graphql-tag';

import type { OpenLoomRecorderParams } from '@confluence/loom-utils';
import {
	ExperienceTrackerContext,
	LOOM_PAGE_HEADER_CONTENT_INSERTION,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { getUrlForContentType } from '@confluence/content-types-utils';

import { useLoomInsertUrlState } from '../useLoomInsertUrlState';
import type {
	PageHeaderLoomButtonQuery as PageHeaderLoomButtonQueryType,
	PageHeaderLoomButtonQueryVariables,
} from '../__types__/PageHeaderLoomButtonQuery';

/**
 * instead of importing from '../PageHeaderLoomButtonQuery.graphql.js', we are defining it here again because of the
 * "no-import-graphql-operations" rule, more info here https://hello.atlassian.net/wiki/spaces/UIP/pages/4227498528/Rule+no-import-graphql-operations
 * (naming convention here https://go/eslint-consistent-graphql-operation-naming)
 */
const usePageHeaderLoomInsertionQuery = gql`
	query usePageHeaderLoomInsertionQuery($contentId: ID!) {
		contentContributors(id: $contentId) {
			isCurrentUserContributor
		}
		content(id: $contentId) {
			nodes {
				id
				operations {
					operation
					targetType
				}
			}
		}
	}
`;

type usePageHeaderLoomInsertionProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
};

export enum PostLoomRecordingCTA {
	EDITOR_INSERT = 'editorInsert',
	PAGE_COMMENT_INSERT = 'pageCommentInsert',
	COPY_LINK = 'copyLink',
	OPEN_ON_LOOM = 'openOnLoom',
}

export const usePageHeaderLoomInsertion = ({
	contentId,
	contentType,
	spaceKey,
}: usePageHeaderLoomInsertionProps): {
	openLoomRecorderParams: OpenLoomRecorderParams | undefined;
	postLoomRecordingCTA: PostLoomRecordingCTA;
	resetInsertFunctionOnUnmount: boolean;
} => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { loadEditor } = useEditPageLoadingActions();
	const [, loomInsertUrlActions] = useLoomInsertUrlState();

	const saveLoomUrlAndLoadEditor = (video: LoomVideo) => {
		loomInsertUrlActions.updateFullPageEditorLoomUrl(video);
		const redirectUrl = getUrlForContentType({
			contentType,
			spaceKey,
			contentId,
			redirectToEditor: true,
		});
		loadEditor({
			contentId,
			spaceKey,
			redirectUrl,
			contentType,
		});
	};

	const beginExperienceTracker = (postLoomRecordingCTA: PostLoomRecordingCTA) => {
		experienceTracker.start({
			name: LOOM_PAGE_HEADER_CONTENT_INSERTION,
			timeout: ExperienceTimeout.LOOM_PAGE_HEADER_CONTENT_INSERTION,
			attributes: { postLoomRecordingCTA },
		});
	};
	const { data } = useQuery<PageHeaderLoomButtonQueryType, PageHeaderLoomButtonQueryVariables>(
		usePageHeaderLoomInsertionQuery,
		{
			variables: { contentId },
		},
	);

	const isContentContributor = Boolean(data?.contentContributors?.isCurrentUserContributor);

	let openLoomRecorderParams: OpenLoomRecorderParams | undefined;
	let postLoomRecordingCTA: PostLoomRecordingCTA = PostLoomRecordingCTA.OPEN_ON_LOOM;
	let resetInsertFunctionOnUnmount = false;

	const contentData = data?.content?.nodes?.[0];

	const canEdit = contentData?.operations?.some(
		(operation) => operation?.operation === 'update' && operation?.targetType === contentType,
	);
	const canComment = contentData?.operations?.some(
		(operation) => operation?.targetType === 'comment' && operation?.operation === 'create',
	);

	if (canEdit && isContentContributor) {
		// editor mode insert
		postLoomRecordingCTA = PostLoomRecordingCTA.EDITOR_INSERT;
		openLoomRecorderParams = {
			onInsert: (_, video: LoomVideo) => {
				beginExperienceTracker(postLoomRecordingCTA);
				saveLoomUrlAndLoadEditor(video);
			},
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Insert Loom video',
		};
	} else if (canComment) {
		// page comment insert
		postLoomRecordingCTA = PostLoomRecordingCTA.PAGE_COMMENT_INSERT;
		// Set to true so that CTA 'Add to comment' will not render if user has navigated to
		// different page post Loom recording.
		resetInsertFunctionOnUnmount = true;
		openLoomRecorderParams = {
			onInsert: (_, video: LoomVideo) => {
				beginExperienceTracker(postLoomRecordingCTA);
				loomInsertUrlActions.updatePageCommentEditorLoomUrl(video);
				const openPageCommentEditorButton = document.getElementById('addCommentButton');
				openPageCommentEditorButton && openPageCommentEditorButton.click();
			},
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Add to comment',
		};
	} else {
		postLoomRecordingCTA = PostLoomRecordingCTA.COPY_LINK;
		// Not defining openLoomRecorderParams here so that
		// undefined will be passed to the Loom SDK resulting
		// in the Loom SDK rendering a single CTA of 'Copy link'
	}

	return {
		openLoomRecorderParams,
		postLoomRecordingCTA,
		resetInsertFunctionOnUnmount,
	};
};
